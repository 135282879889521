import Login from "./pages/Login";
import Reset from "./pages/Reset";
import Verification from "./pages/Verification";
import Dashboard from "./pages/Dashboard";
import ChangePswd from "./pages/ChangePswd";
import ResetSuccess from "./pages/ResetSuccess";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  withRouter,
} from "react-router-dom";
//   import PDFViewer from "pdf-viewer-reactjs";
// import { useState } from 'react';

function App() {
  let routes = (
    <Switch>
      <Route path="/" component={Login} exact />

      <Route path="/reset" component={Reset} exact />
      <Route path="/verification" component={Verification} exact />
      <Route path="/changepassword" component={ChangePswd} exact />
      <Route path="/success" component={ResetSuccess} exact />
  
        <Route path="/home" component={Dashboard} exact />
    
    </Switch>
  );

  return (
    <Router>
      <Switch>{routes}</Switch>
    </Router>
  );
}

export default App;
