import React, { useState } from "react";
import Classes from "./Auth.module.css";
import { useHistory } from "react-router-dom";
import axios from "axios";
import * as Urls from "../../../Urls";

export default function ChangePassword() {
  const [password, setPassword] = useState("");
  const [confirmPaswd, setConfirmPswd] = useState("");
  const [validateError, setValidateError] = useState("");
  const history = useHistory();
  const loginPageHandler = () => {
    history.push("/");
  };
  const passwordHandler = (e) => {
    setPassword(e.target.value);
  };
  const confirmPswdHandler = (e) => {
    setConfirmPswd(e.target.value);
  };
  const changePswdHandler = (e) => {
    e.preventDefault();
    if (password === confirmPaswd) {
      setValidateError("");
      e.preventDefault();
      const formData = new URLSearchParams();
      formData.append("user_id", history.location.state.userId);
      formData.append("password", password);

      axios
        .post(Urls.changePswd, formData, {
          headers: { "content-type": "application/x-www-form-urlencoded" },
        })
        .then((response) => {
          if (response.data.status === "Success") {
            history.push("/success");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setValidateError("Password and Confirm Password does not match");
    }
  };
  return (
    <div className={Classes.Card}>
      <div className={Classes.Auth}>
        <h3>Change Password</h3>
        <div className="error">{validateError}</div>
        <form className={Classes.logForm} autoComplete="off" onSubmit={loginPageHandler}>
          <label htmlFor="newPas">Enter New Password</label>
          <br />
          <input
            type="password"
            value={password}
            onChange={passwordHandler}
            autoComplete="new-password"
            placeholder="New password"
          />
          <br />

          <label htmlFor="conPaswd">Re-enter New Password</label>
          <br />
          <input
            type="password"
            value={confirmPaswd}
            onChange={confirmPswdHandler}
            autoComplete="new-password"
            placeholder="Re-enter new password"
          />
          <br />
        </form>
        <div className="d-flex justify-content-center">
          <button
            type="submit"
            className={Classes.Submit}
            onClick={changePswdHandler}
          >
            CONFIRM
          </button>
        </div>
      </div>
      <p className={Classes.Reset} onClick={loginPageHandler}>
        {"<< Back to Login"}
      </p>
    </div>
  );
}
