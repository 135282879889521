import React,{useState} from "react";
import Classes from "./Header.module.css";
import { AiTwotoneFilter } from "react-icons/ai";
import { IoCallOutline } from "react-icons/io5";
import {BiTestTube} from 'react-icons/bi'
import {FiSearch} from 'react-icons/fi'

export default function DashboardLay(props) {
  const[searShow,setSearchShow] = useState(false)
  const [searchVal,setSearchVal] = useState('')
  const searchKeyHanlder = (e) =>{
    if(e.target.value.length !==0){
      setSearchShow(true)
      props.searchKey(e.target.value)
      setSearchVal(e.target.value)
     

    }
    else{
      props.searchClear()
      setSearchShow(false)
      setSearchVal('')
     
    }
   
  }
  const searchTitleHandler = (title) =>{
    props.searchTitle(title)
    setSearchShow(false)
    setSearchVal(title.value)

  }

  return (
    <div style={{ padding: "0px 15px" }}>
      <div className={Classes.MidHead}>
        <div className={Classes.PatntName}>Patient Reports</div>
        <div className="d-flex">
          <div className={Classes.Filter} onClick={props.clicked}>
            Apply Filter <AiTwotoneFilter color="#FFFFFF" size={20} />
          </div>
          <div className={Classes.searchList}>
            <input
              className={Classes.searchbar}
              type="text"
              onChange={searchKeyHanlder}
              value={searchVal}
              placeholder="Search by Doctor Name/ Patient Name/ Test Name...     "
            />
            <FiSearch size={18} className={Classes.searchIcon}/>
            <div className={Classes.searchListCont} style={{display:searShow?'block':'none'}}>
              {props.sugList.length !== 0?props.sugList.map((item,index)=>{
                return(
                  <p className={Classes.SearchItem} key={index} onClick={()=>searchTitleHandler(item)}><BiTestTube color='#808080' size={20}/>&nbsp;&nbsp;&nbsp;{item.value}</p>

                )
              }):<p className={Classes.noResult} >No results found</p>}
            
             
            </div>
          </div>
        </div>
      </div>
      {props.children}

      <div className={Classes.Footer}>
        <div className="row">
          <div className="col-md-4">
            <p>Copyright © 2023 Access Home Lab Solutions</p>
          </div>
          <div className="col-md-4">
            <div className={Classes.call}>
              <IoCallOutline color="#1F6CAB" size={30} />
              <span className={Classes.Phone}>(+91) 9288008801</span>
            </div>
          </div>
          <div className="col-md-4">
            <h5>All Rights Reserved | Terms and Conditions | Privacy Policy</h5>
          </div>
        </div>
      </div>
    </div>
  );
}
