import React from "react";
import Classes from "./AuthLayout.module.css";
import Logo from "../../../assets/images/logo.png";
import Vector from "../../../assets/images/Vector1.png";
import Vector2 from "../../../assets/images/Vector2.png";
import Vector3 from "../../../assets/images/Vector3.png";
import { IoCallOutline } from "react-icons/io5";

export default function AuthLayout(props) {
  return (
    <div className={Classes.AuthLayLog}>
      <img src={Vector} alt="vector" className={Classes.vector1} />
      <img src={Vector2} alt="vector" className={Classes.vector2} />
      <img src={Vector3} alt="vector" className={Classes.vector3} />
      <div className={Classes.logo_parent}>
      <img src={Logo} alt="logo" className={Classes.Logo} />
      </div>
      <div>{props.children}</div>
      <div className={Classes.call}>
        <IoCallOutline color="#1F6CAB" size={30} />
        <span className={Classes.Phone}>(+91) 9288008801</span>
      </div>
      <p className={Classes.Privacy}>
        I hereby agree and accept the{" "}
        <span style={{ color: "#1F6CAB" }}>Terms of service</span> and{" "}
        <span style={{ color: "#1F6CAB" }}>Privacy policy</span>
      </p>
    </div>
  );
}
