import React, { useState, useEffect } from "react";
import Classes from "./Auth.module.css";
import { useHistory } from "react-router-dom";
import axios from "axios";
import * as Urls from "../../../Urls";

export default function Login() {
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [validateError, setValidateError] = useState("");
  const history = useHistory();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = "rIgXxcMw5FnINz26SCuT2DQf5ZAKv1";

        const response = await axios.get(
          "https://accesslabbeta.stagingserverhub.com/api/b2b/list/message?order_id=13901",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      } catch (error) {
        // Handle errors
        console.error(error);
      }
    };
    fetchData();
  }, []);
  const resetPageHandler = () => {
    history.push("/reset");
  };
  const userNameHandler = (e) => {
    setUserName(e.target.value);
  };
  const passwdChangeHandler = (e) => {
    setPassword(e.target.value);
  };
  const loginHandler = async (e) => {
    if (userName.length === 0 || password.length === 0) {
      setValidateError("username and password required!!");
    } else {
      setValidateError("");
      e.preventDefault();

      const body = {
        client_id: "H1etIL2pdJyxI6um9lXzJM3mvCJX2t",
        client_secret: "hDSppUhp43IIBi3pYPMZB3PdkIg3VQ",
        user_name: userName,
        password: password,
        grant_type: "client_credentials",
      };
      try {
        const response = await axios.post(Urls.login, body, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (response.data.result.status === "ok") {
          localStorage.setItem("accesToken", response.data.result.data.token);
          history.push({
            pathname: "/home",
            state: {
              data: response.data.result.data.user_id,
              name: response.data.result.data.name,
              user: response.data.result.data.image
            },
          });
        } else {
          setValidateError(response.data.result.error.message);
        }
      } catch (error) {
        // Handle errors
        console.error(error);
      }
    }
  
  };
  return (
    <div className={Classes.Card}>
      <div className={Classes.Auth}>
        <h3>Login</h3>
        <div className="error">{validateError}</div>
        <form className={Classes.logForm} autoComplete="off" onSubmit={loginHandler}>
          <label>Username</label>
          <br />
          <input
            type="text"
            value={userName}
            onChange={userNameHandler}
            id="fname"
            name="firstname"
            placeholder="Enter email Id"
            required={true}
          />
          <br />

          <label>Password</label>
          <br />
          <input
            type="password"
            id="lname"
            name="lastname"
            value={password}
            onChange={passwdChangeHandler}
            placeholder="Password"
            required
          />
          <br />

          <div className="d-flex justify-content-center">
            <button
              type="submit"
              className={Classes.Submit}
              onClick={loginHandler}
            >
              SUBMIT
            </button>
          </div>
          <p className={Classes.Reset} onClick={resetPageHandler}>
            Reset Password
          </p>
        </form>
      </div>
    </div>
  );
}
