import React,{useState,useEffect} from 'react'
import Classes from './Auth.module.css'
import { useHistory } from 'react-router-dom'
import axios from 'axios'

export default function ChangePassword(props) {
  const [otp,setOtp] = useState('')
  const [validateError, setValidateError] = useState("");
  const history = useHistory()
  useEffect(()=>{


  },[])
  const loginPageHandler = () =>{
    history.push('/')
  }
  const otpHandler =  (e) =>{
    setOtp(e.target.value)

  }
  const otpVerifyHandler = async(e) =>{
    if(otp.length === 0){
      setValidateError('Enter OTP')
    }
    else{
      
      setValidateError('')
      e.preventDefault();
      if(otp === history.location.state.data){
        history.push({pathname:'/changepassword',state:{userId:history.location.state.userId}})

      }
     

    }
  }
  return (
    <div className={Classes.Card}>
    <div className={`${Classes.Auth} ${Classes.registertext}`}>
      <h5>An OTP has been sent to your registered email <br /> address</h5>
      <form className={Classes.logForm} autoComplete='off' onSubmit={otpVerifyHandler}>
        <label htmlFor="fname">Enter OTP received on your email</label>
        <br />
        <input
          type="text"
          value={otp}
          onChange={otpHandler}
          id="otp"
          
          placeholder="Enter OTP"
        />
        <br />

        <p className={`${Classes.Reset} ${Classes.resetoption}`}>Resend OTP</p>

        <div className="d-flex justify-content-center verification-btn">
          <button type="submit" className={Classes.Submit} onClick={otpVerifyHandler}>
            SUBMIT
          </button>
        </div>
        
      </form>
    </div>
    <p className={`${Classes.Reset} ${Classes.resetoption}`} onClick={loginPageHandler}>{'<< Back to Login'}</p>
  </div>
  )
}
