import React, { useState, useEffect } from "react";
import Classes from "./Auth.module.css";
import { useHistory } from "react-router-dom";
import axios from "axios";
import * as Urls from "../../../Urls";

export default function Reset() {
  const [email, setEmail] = useState("");
  const [validateError, setValidateError] = useState("");
  const history = useHistory();
  const loginPageHandler = () => {
    history.push("/");
  };
  const emailHandler = (e) => {
    setEmail(e.target.value);
  };
  const resetHandler = (e) => {
    e.preventDefault();
    if (email.length === 0) {
      setValidateError("Email is Required");
    } else {
      setValidateError("");
      e.preventDefault();
      const formData = new URLSearchParams();
      formData.append("email", email);

      axios
        .post(
         Urls.verify,
          formData,
          {
            headers: { "content-type": "application/x-www-form-urlencoded" },
          }
        )
        .then((response) => {
          if (response.data.status === "Fail") {
            setValidateError(response.data.message);
          } else if (response.data.status === "Success") {
            history.push({
              pathname: "/verification",
              state: { data: response.data.otp, userId: response.data.user_id },
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  return (
    <div className={Classes.Card}>
      <div className={Classes.Auth}>
        <h3>Reset Password</h3>
        <div className="error">{validateError}</div>
        <form className={Classes.logForm} autoComplete="off" onSubmit={loginPageHandler}>
          <label htmlFor="fname">Email ID</label>
          <br />
          <input
            type="text"
            id="email"
            value={email}
            onChange={emailHandler}
            placeholder="Enter email Id"
          />
          <br />
          <div className="d-flex justify-content-center">
            <button
              type="submit"
              className={Classes.Submit}
              onClick={resetHandler}
            >
              SUBMIT
            </button>
          </div>
        </form>
      </div>
      <p className={Classes.Reset} onClick={loginPageHandler}>
        {"<< Back to Login"}
      </p>
    </div>
  );
}
