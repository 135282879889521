import React, { useState, useEffect, useRef } from "react";
import Header from "../components/Header/Header";
import DashboardLay from "../components/Header/DashboardLay";
import DownLoad from "../assets/images/download.png";
import Msg from "../assets/images/msg.png";
import { Button, Modal } from "antd";
import { AiFillCloseCircle } from "react-icons/ai";
import axios from "axios";
import * as Urls from "../Urls";
import { DatePicker, Input, Select, Form } from "antd";
import { FiSearch } from "react-icons/fi";
import { IoClose } from "react-icons/io5";
import ReactPaginate from "react-paginate";
import { Document, Page } from "react-pdf";
import { pdfjs } from "react-pdf";
import pdfS from "../assets/images/pdf.pdf";
import { TfiAngleLeft } from "react-icons/tfi";
import { TfiAngleRight } from "react-icons/tfi";
import { MdSimCardDownload } from "react-icons/md";
import {FadeLoader} from 'react-spinners'
import moment from 'moment';

export default function Dashboard(props) {
  const [modal1Open, setModal1Open] = useState(false);
  const [show, setShow] = useState(false);
  const [orderList, setOrderList] = useState([]);
  const [visible, setVisible] = useState(false);
  const [billNo, setBillNo] = useState("");
  const [hospitalId, setHospitalId] = useState("");
  const [status, setStatus] = useState("");
  const [patName, setPatName] = useState("");
  const [msgVisSet, setMsgVisSet] = useState([{ orderId: "" }]);
  const [msgCont, setMsgCont] = useState("");
  const [validateError, setValidateError] = useState("");
  const [pageCount, setPageCount] = useState("");
  const [pdf, setPdf] = useState("");
  const [loading,setLoading] = useState(false)
  const [patientDet, setPatientDet] = useState({
    patient_name: "",
    age: "",
    gender: "",
    bill_no: "",
    bill_date: "",
  });
  const [filStartDate, setFilStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [doctId, setDoct] = useState("");
  const token = localStorage.getItem("accesToken");
  const [doctorList, setDoctorList] = useState([]);
  const formRef = useRef(null);
  const startRef = useRef(null);
  const referRef = useRef(null);
  const statusRef = useRef(null);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [pdfView, setPdfView] = useState(false);
  const [pdfName, setPdfName] = useState("");
  const [suggetionList,setSuggetionList] = useState([])
  const [activeMode,setActiveMode] = useState('')
  const [searchKeyPage,setSearchKeyPage] = useState(null)
  const [pdfShowError,setPdfShowError] = useState(true)
  const modalOpenHandler = (selPatient) => {
    setModal1Open(true);
    setPatientDet(selPatient);
  };
  const listingOrder = (userId, pageNo) => {
    setLoading(true)
    const formData = new URLSearchParams();
    formData.append("user_id", userId);
    formData.append("page_number", pageNo);

    axios
      .post(Urls.listOrder, formData, {
        headers: {
          "content-type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setLoading(false)
        setActiveMode('all')
        setOrderList(response.data.data);
        setPageCount(Math.ceil(response.data.count / 20));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
    listingOrder(props.location.state.data, "1");

    axios
      .get(Urls.listDoctor, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        let newArray = [];
        for (let i = 0; i < response.data.data.length; i++) {
          newArray.push({
            label: response.data.data[i].doctor,
            value: response.data.data[i].id,
          });
        }
        setDoctorList(newArray);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  const modalCloseHandler = () => {
    setModal1Open(false);
  };
  const messegeShowHandler = (selItem, key) => {
    setMsgVisSet([{ orderId: key }]);
    setShow(true);
  };
  const fromChange = (date, dateString) => {
    setFilStartDate(dateString);
    if(dateString === ''){
      setFilStartDate('')
      searchHandler(dateString,endDate,patName,billNo,doctId,hospitalId,status)

    }
    
   
  };
  const toDateChange = (date, dateString) => {
    setEndDate(dateString);
    if(dateString === ''){
      setEndDate('')
      searchHandler(filStartDate,dateString,patName,billNo,doctId,hospitalId,status)

    }
  };
  const statusChange = (stats) => {
    setStatus(stats);
    searchHandler(filStartDate,endDate,patName,billNo,doctId,hospitalId,stats)
  };
  const doctorChange = (doctorId) => {
    setDoct(doctorId);
    searchHandler(filStartDate,endDate,patName,billNo,doctorId,hospitalId,status)

  };
  const nameChange = (e) => {
    setPatName(e.target.value);
    if(e.target.value === ''){
      setPatName('')
      searchHandler(filStartDate,endDate,'',billNo,doctId,hospitalId,status)
    }
  };
  const billNoChange = (e) => {
    setBillNo(e.target.value);
    if(e.target.value === ''){
      setBillNo('')
      searchHandler(filStartDate,endDate,patName,'',doctId,hospitalId,status)
      
    }
  };
  const hosIdChange = (e) => {
    setHospitalId(e.target.value);
    if(e.target.value === ''){
      setHospitalId('')
      searchHandler(filStartDate,endDate,patName,billNo,doctId,'',status)
    
    }
    
  };
  const clearField = () => {
    const formInstance = formRef.current;
    formInstance.setFieldsValue({ datePickerFieldName: null });
    const formInstanceS = startRef.current;
    formInstanceS.setFieldsValue({ datePickerFieldName: null });
    const formInstanceRefer = referRef.current;
    formInstanceRefer.setFieldsValue({ datePickerFieldName: null });
    const formInstanceStatus = statusRef.current;
    formInstanceStatus.setFieldsValue({ datePickerFieldName: null });
    setBillNo("");
    setEndDate("");
    setFilStartDate("");
    setHospitalId("");
    setPatName("");
    setStatus("");
    setDoct("");
    setActiveMode('all')
  };
  const clearHandler = () => {
    clearField();
    listingOrder(props.location.state.data, "1");
  };
  function onDocumentLoadSuccess({ numPages }) {
    console.log(pdf);
    setPdfShowError(false)
    setNumPages(numPages);
    setPageNumber(1);
  }

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }
  const filterVisiblity = () => {
    setVisible(!visible);
  };
  const pdfHandler = (selPdf, pdfName) => {
    console.log(selPdf);
    if(selPdf === false || selPdf === true || selPdf === null){
      setPdfShowError(true)

    }
    else{
      setPdfShowError(false)

    }
    setPdfName(pdfName);

   
    setPdfView(true);
    //  const byteArray = atob(selPdf);
    // const byteNumbers = new Array(byteArray.length);
    // for (let i = 0; i < byteArray.length; i++) {
    //   byteNumbers[i] = byteArray.charCodeAt(i);
    // }
    // const byteArrayUint = new Uint8Array(byteNumbers);
    // const blob = new Blob([byteArrayUint], { type: 'application/pdf' });
    // const blobUrl = URL.createObjectURL(blob);
    setPdf(selPdf);

    // return () => {
    //   URL.revokeObjectURL(blobUrl); // Clean up the object URL when component unmounts
    // };
  };
  const searchHandler = (filStDate,filEndDate,patname,billno,docId,hosId,stats) => {
    setLoading(true)
    axios
      .get(
        Urls.filter +
          "user_id=" +
          props.location.state.data +
          "&start_date=" +
          filStDate +
          "&page_number=1" +
          "&end_date=" +
          filEndDate +
          "&patient_name=" +
          patname +
          "&bill_number=" +
          billno +
          "&doctor_id=" +
          docId +
          "&uhid=" +
          hosId +
          "&status=" +
          stats,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setOrderList(response.data.data);
        setActiveMode('filter')
        setPageCount(Math.ceil(response.data.count / 20));
        setLoading(false)

        // clearField();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const searchFiltHandler = () =>{
    searchHandler(filStartDate,endDate,patName,billNo,doctId,hospitalId,status)

  }
  const handlePageClick = (data) => {
    setLoading(true)
    let pageNum = data.selected+1
    // console.log(data.selected+1)
  
    if (activeMode === 'filter') {
    
      axios
        .get(
          Urls.filter +
            "user_id=" +
            props.location.state.data +
            
            "&start_date="+
            filStartDate +
          
            "&end_date="+
            endDate +
            "&patient_name="+
            patName +
            "&bill_number="+
            billNo +
            "&doctor_id="+
            doctId +
            "&uhid=" +
            "&page_number="+
            pageNum+
            hospitalId+
            "&status="+
            status,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          setOrderList(response.data.data);
          setPageCount(Math.ceil(response.data.count / 20));
          setActiveMode('filter')
          setLoading(false)
          // setBillNo("");
          // setEndDate("");
          // setFilStartDate("");
          // setHospitalId("");
          // setPatName("");
          // setStatus("");
          // setDoct("");
        })
        .catch((error) => {
          console.log(error);
        });
    } else if(activeMode === 'all') {
      setActiveMode('all')
      listingOrder(props.location.state.data, data.selected + 1);
    }
    else if(activeMode === 'search'){
      setLoading(true)
      let pageNo = data.selected + 1
      const searchVal = searchKeyPage.value
      const urlEn = searchVal.includes('#')? 
      "?user_id=" +
      props.location.state.data +'&page_number='+pageNo+"&"+searchKeyPage.title+"="+searchKeyPage.value+'='+encodeURIComponent('#'):"?user_id=" +
      props.location.state.data +'&page_number='+pageNo+"&"+searchKeyPage.title+"="+searchKeyPage.value
      axios
      .get(
      
        Urls.search +
          urlEn,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        console.log(response);
        setLoading(false)
        setOrderList(response.data.data)
         setActiveMode('search')
        
         setPageCount(Math.ceil(response.data.count / 20));
      
      
      })
      .catch((error) => {
        console.log(error);
      });
    }
  };
  const msgCloseHandler = () => {
    setShow(false);
    setValidateError("");
    setMsgCont('')
  };
  const msgChangeHandler = (e) => {
    setMsgCont(e.target.value);
  };
  const msgSendHandler = (selItem) => {
    if (msgCont.length === 0) {
      setValidateError("Type your message");
    } else {
      setValidateError("");

      const formData = new URLSearchParams();
      formData.append("order_id", selItem.order_id);
      formData.append("line_id", selItem.orderline_id);
      formData.append("message", msgCont);

      axios
        .post(Urls.msg, formData, {
          headers: {
            "content-type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          if (response.data.status === "Success") {
            setShow(false);
            setMsgCont("");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  const pdfCloseHandler = () => {
    setPdfView(false);
  };
  const downLoadPdf = () => {
    const fileUrl = pdf; // Replace with your PDF URL
    const fileName = pdfName + ".pdf"; // Specify the desired filename
    // const link = document.createElement('a');
    // link.href = pdf;
    // link.download = pdfName + ".pdf";

    // // Trigger the download by programmatically clicking the link
    // document.body.appendChild(link);
    // link.click();
    // document.body.removeChild(link);

    fetch(fileUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const a = document.createElement("a");
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        a.remove();
      })
      .catch((error) => {
        console.error("Error downloading the PDF:", error);
      });
  };
  const searckKeyHandler = (searckWrd) =>{
    const qs = require('qs');
    console.log(searckWrd);
    const formData = new URLSearchParams();
    formData.append("search_val", searckWrd);
    formData.append("user_id", props.location.state.data);
    const data = {
      search_val: searckWrd,
      user_id: props.location.state.data
    };
    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: `Bearer ${token}`,
      },
      params: data,
      paramsSerializer: params => {
        return qs.stringify(params, { encode: false });
      }
    };
   axios
      .get(Urls.suggetion,config
      )
      .then((response) => {
        setSuggetionList(response.data.data)
        
      
        console.log(response);
      
      })
      .catch((error) => {
        console.log(error);
      });
  

  }
  const searchTitleHandler = (searchKeys) =>{
    setLoading(true)
    const searchVal = searchKeys.value
      const urlEn = searchVal.includes('#')?"?user_id=" +
      props.location.state.data +'&page_number=1'+"&"+searchKeys.title+"="+searchKeys.value+'='+encodeURIComponent('#'): "?user_id=" +
      props.location.state.data +'&page_number=1'+"&"+searchKeys.title+"="+searchKeys.value
    axios
    .get(
      Urls.search +
        urlEn,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      console.log(response);
      setLoading(false)
       setOrderList(response.data.data)
       setActiveMode('search')
       setSearchKeyPage(searchKeys)
       setPageCount(Math.ceil(response.data.count / 20));
    
    
    })
    .catch((error) => {
      console.log(error);
    });

  }
 
  const searchClearHandler =() =>{
    listingOrder(props.location.state.data, "1");

  }
  let orderLists;
  if(loading){ 
    orderLists =  <div className='d-flex justify-content-center align-items-center loader'> <FadeLoader color="#00464d" /></div>

  }
  else if(orderList.length ===0){
    orderLists = 
    <table className="table">
      <thead></thead>

      <tr className="tabHead">
        <th>Order No</th>
        <th>Date</th>
        <th>Patient Name</th>
        <th>Hospital ID</th>
        <th>Test Name</th>
        <th>Doctor Name</th>
        <th>ETA</th>
        <th>Status</th>
        <th>Action</th>
      </tr>
      <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>No results found</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              </tr>
              <tfoot></tfoot>
              </table>
  }
  else{
    orderLists = 
    <table className="table">
      <thead></thead>

      <tr className="tabHead">
        <th>Order No</th>
        <th>Date</th>
        <th>Patient Name</th>
        <th>Hospital ID</th>
        <th>Test Name</th>
        <th>Doctor Name</th>
        <th>ETA</th>
        <th>Status</th>
        <th>Action</th>
      </tr>
      {orderList.map((item, index) => {
              return (
                <tr
                className="tabSubHead"
                  key={index}
                  style={{
                    backgroundColor: item.is_eta_crossed ? "#F1FCFC" : "",
                  }}
                >
                  <td>{item.order_ref}</td>
                  <td style={{width:'100px'}}>{moment(item.date, 'YYYY-MM-DD').format('YYYY-MM-DD').split('-').reverse().join('-')}</td>
                  <td style={{width:'160px'}} className="tooltipss">{item.patient_name}
                  <div className="tooltiptext">
                            <div className="name_b">{item.patient_details.patient_name}</div>
                            <div className="parent_base">
                                <div className="left_base">
                                    <div className="age">Age : {item.patient_details.age}</div>
                                    <div className="gender_sec">Gender : {item.patient_details.gender}</div>
                                </div>
                                <div className="left_base">
                                    <div className="bill_number">Bill No : {item.patient_details.bill_no}</div>
                                    <div className="bill_date">Bill Date : {moment(item.patient_details.bill_date, 'YYYY-MM-DD').format('YYYY-MM-DD').split('-').reverse().join('-')}</div>
                                </div>
                            </div>
                        </div>
                   
                  </td>
                  <td>{item.hospital_id}</td>
                  <td>{item.test_name} </td>
                  <td>{item.doctor}</td>
                  <td style={{width:'160px'}}>{moment(item.eta).format('HH:mm:ss').split(/[- :]/).join(':')+','+moment(item.eta).format('YYYY-MM-DD').split(/[- :]/).reverse().join('-')}</td>
              
                  <td
                    style={{ border: "none" }}
                    className={item.has_result ? "Ready" : "Drop"}
                  >
                    {item.status}
                  </td>
                  <td className="dropdown" style={{ position: "relative" }}>
                    <div className="Action">
                      {item.has_result && (
                        <img
                          src={DownLoad}
                          alt="download"
                          onClick={() =>{item.result.length !==0&&pdfHandler(
                            item.result[0].attachment,
                            item.patient_details.patient_name
                          )}
                           
                          }
                          style={{ marginRight: "15px", cursor: "pointer" }}
                        />
                      )}
                      <img
                        src={Msg}
                        alt="msg"
                        className="myDIV"
                        onClick={() => messegeShowHandler(item, index)}
                      />
                      {msgVisSet[0].orderId === index && (
                        <div
                          className="comment-box hide"
                          style={
                            show ? { display: "block" } : { display: "none" }
                          }
                        >
                          <div className="msgBox">
                            <h3 className="msgHead">{item.order_ref}</h3>

                            <AiFillCloseCircle
                              color={"#1F6CAB"}
                              size={22}
                              onClick={msgCloseHandler}
                              style={{ cursor: "pointer" }}
                            />
                          </div>
                          <textarea className="comment_box"
                            name=""
                            id=""
                            cols="50"
                            rows="3"
                            value={msgCont}
                            onChange={msgChangeHandler}
                            placeholder="Type your messages here"
                          ></textarea>
                          <div className="errorNew">{validateError}</div>
                          <div
                            className="msgSubmit"
                            onClick={() => msgSendHandler(item)}
                          >
                            Send
                          </div>
                        </div>
                      )}
                    </div>
                  </td>
                </tr>
              );
            })}
            <tfoot></tfoot>
            </table>

  }
  return (
    <div>
      {/* <Modal
        style={{
          top: 270,
          width: 400,
        }}
        open={modal1Open}
        onOk={() => setModal1Open(false)}
        onCancel={() => setModal1Open(false)}
        footer={null}
        className="custom-modal"
        mask={false}
        closable={false}
      >
        <div style={{ padding: "20px" }}>
          <div className="PatName">{patientDet.patient_name}</div>
          <div className="d-flex">
            <div className="patDet">Age : {patientDet.age}</div>
            <div className="patDet" style={{ marginLeft: "50px" }}>
              Gender : {patientDet.gender}
            </div>
          </div>
          <div className="d-flex">
            <div className="patDet">Bill No : {patientDet.bill_no}</div>
            <div className="patDet" style={{ marginLeft: "50px" }}>
              Bill Date : {patientDet.bill_date}
            </div>
          </div>
        </div>
      </Modal> */}
      <Modal
        style={{
          top: 50,
          padding: 0,
        }}
        bodyStyle={{ background: "#E4FBFB", paddingTop: "30px" }}
        open={pdfView}
        onOk={() => setPdfView(false)}
        onCancel={() => setPdfView(false)}
        footer={null}
      >
        <h3 className="pdfHead">Report Preview {pdfName}</h3>
        <div className="d-flex justify-content-center align-items-center">
          <Document file={pdf} onLoadSuccess={onDocumentLoadSuccess} >
            <Page pageNumber={pageNumber} />
          </Document>
        </div>

       {!pdfShowError&& <div style={{ paddingBottom: "40px" }}>
          <div className="d-flex justify-content-cemter align-items-center">
            {pageNumber==1?<TfiAngleLeft/>:<TfiAngleLeft onClick={previousPage} style={{cursor:'pointer'}}/>}
            <p style={{ marginTop: "15px" }}>
             
              &nbsp;&nbsp;&nbsp;Page {pageNumber || (numPages ? 1 : "--")} of{" "}
              {numPages || "--"}&nbsp;&nbsp;&nbsp;
            </p>
            {numPages !== pageNumber?<TfiAngleRight onClick={nextPage} style={{cursor:'pointer'}}/>:<TfiAngleRight/>}
          </div>
        </div>}
        <div className="pdf">
          <div className="pdfBtns">
          {!pdfShowError&& <div className="downLoad" onClick={downLoadPdf}>
              <MdSimCardDownload color="#F5F5F5" size={30} />
              Download
            </div>}
            <div className="close" onClick={pdfCloseHandler}>
              <MdSimCardDownload color="#F5F5F5" size={30} />
              Close
            </div>
          </div>
        </div>
      </Modal>
      <Header name={props.location.state.name} imgUrl={props.location.state.user}/>
      <DashboardLay clicked={filterVisiblity} searchKey={searckKeyHandler} 
            sugList={suggetionList} searchTitle={searchTitleHandler}
            searchClear={searchClearHandler}>
        {visible && (
          <div className="filterCont">
            <div className="row">
              <div className="col-md-4">
                <div className="d-flex align-items-center justify-content-between filtDet filter_boxes">
                  <p>From Date</p>
                  <div className="filtDate form_date">
                    <Form
                      ref={startRef}
                      initialValues={{ datePickerFieldName: null }}
                    >
                      <Form.Item name="datePickerFieldName">
                        <DatePicker format="DD MMM YYYY" onChange={fromChange} style={{width:'183px'}} />
                      </Form.Item>
                    </Form>
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-between filtDet filter_boxes">
                  <p>Patient Name</p>
                  <div className="filtDate patient_name">
                    <Input onChange={nameChange} value={patName} />
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-between filtDet filter_boxes" style={{ paddingTop: "20px" }}>
                  <p>Bill No</p>
                  <div className="filtDate bill_no">
                    <Input onChange={billNoChange} value={billNo} />
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="d-flex align-items-center justify-content-between filtDet filter_boxes">
                  <p>To Date</p>
                  <div className="filtDate To_date">
                    <Form
                      ref={formRef}
                      initialValues={{ datePickerFieldName: null }}
                    >
                      <Form.Item name="datePickerFieldName">
                        <DatePicker format="DD MMM YYYY" onChange={toDateChange} style={{width:'183px'}} />
                      </Form.Item>
                    </Form>
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-between filtDet filter_boxes">
                  <p>Hospital ID</p>
                  <div className="filtDate hospital_id">
                    <Input onChange={hosIdChange} value={hospitalId} />
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="d-flex align-items-center justify-content-between filtDet filter_boxes">
                  <p>Refer by</p>
                  <div className="filtDate refer_by">
                    <Form
                      ref={referRef}
                      initialValues={{ datePickerFieldName: null }}
                    >
                      <Form.Item name="datePickerFieldName">
                        <Select
                          onChange={doctorChange}
                          style={{
                            width: 187,
                          }}
                          options={doctorList}
                        />
                      </Form.Item>
                    </Form>
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-between filtDet filter_boxes">
                  <p>Status</p>
                  <div className="filtDate status-f">
                    <Form
                      ref={statusRef}
                      initialValues={{ datePickerFieldName: null }}
                    >
                      <Form.Item name="datePickerFieldName">
                        <Select
                          onChange={statusChange}
                          style={{
                            width: 187,
                          }}
                          options={[
                            {
                              value: "ready",
                              label: "Ready",
                            },
                            {
                              value: "lab_dropped",
                              label: "Lab dropped",
                            },
                          ]}
                        />
                      </Form.Item>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {visible && (
          <div className=" col-md-12">
            <div className="Box">
              <p className="note">
                Please provide search criteria and hit search button.
              </p>
              <div className="d-flex">
                <div className="FilSearch" onClick={searchFiltHandler}>
                  <FiSearch size={16} />
                  &nbsp;Search
                </div>
                <div className="FilClear" onClick={clearHandler}>
                  <IoClose size={18} />
                  &nbsp;Clear
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="tabCon" style={{ padding: "25px" }}>
        {orderLists}
          <ReactPaginate
            breakLabel="..."
            nextLabel="next >"
            onPageChange={handlePageClick}
            marginPagesDisplayed={3}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel="< prev"
            renderOnZeroPageCount={null}
            containerClassName={"pagination justify-content-end pageout"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            previousClassName={"page-item"}
            previousLinkClassName={"page-link"}
            nextClassName={"page-item"}
            nextLinkClassName={"page-link"}
            breakClassName={"page-item"}
            breakLinkClassName={"page-link"}
            activeClassName={"active"}
          />
        </div>
      </DashboardLay>
    </div>
  );
}
