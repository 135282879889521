import React from "react";
import AuthLayout from "../components/Auth/AuthLayout/AuthLayout";
import Verify from "../assets/images/verify.png";
import { useHistory } from "react-router-dom";

export default function ResetSuccess() {
  const history = useHistory();
  const loginHandler = () => {
    history.push("/");
  };
  return (
    <div className="container-fluid">
      <div className="row ">
        <div className="col-md-7" style={{height:'100vh',display:'flex',alignItems:'center',justifyContent:'center'}}>
          <AuthLayout>
            <div className="SuccessPad">
              <div className="successCard">
                <h3>
                  <span style={{ fontWeight: "700" }}>
                    Password reset successful !
                  </span>{" "}
                  <br />
                  Password reset Successful.Kindly Login to proceed
                </h3>
              </div>
              <button style={{marginLeft:'20px'}} type="submit" className="succLog" onClick={loginHandler}>
                LOGIN NOW
              </button>
            </div>

            {/* <Verifications /> */}
          </AuthLayout>
        </div>
        <div className="col-md-5 imgContainer">
          {/* <img src={Verify} alt="log" className="img-responsive verifySide" /> */}
        </div>
      </div>
    </div>
  );
}
