import React from "react";
import AuthLayout from "../components/Auth/AuthLayout/AuthLayout";
import Verifications from "../components/Auth/Auth/Verification";
import Verify from "../assets/images/verify.png";
export default function Verification() {
  return (
    <div className="container-fluid">
      <div className="row ">
        <div className="col-md-7" style={{height:'100vh',display:'flex',alignItems:'center',justifyContent:'center'}}>
          <AuthLayout>
            {/* <h3 className="mainHead">Report Download portal</h3> */}
            <Verifications />
          </AuthLayout>
        </div>
        <div className="col-md-5 imgContainer">
            {/* <img src={Verify} alt='log' className='img-responsive verifySide'/> */}
            </div>
      </div>
    </div>
  );
}
