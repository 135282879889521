import React,{useEffect, useState} from "react";
import Classes from "./Header.module.css";
import Logo from "../../assets/images/logo.png";
import Logout from "../../assets/images/logout.png";
import User from "../../assets/images/user.png";
import axios from "axios";
import * as Urls from "../../Urls";
import { useHistory } from "react-router-dom";

export default function Header(props) {
  const [userProfile,setUserProfile] = useState('')
  const history = useHistory();
  useEffect(()=>{
    setUserProfile(props.imgUrl)
  },[])
 
  const logoutHandler = () => {
    axios
      .post(Urls.logout)
      .then((response) => {
        if (response.data.status === "Success") {
          localStorage.removeItem("accesToken");
          history.push("/");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <div className={Classes.Header}>
      <img src={Logo} className="img-responsive" alt="logo" />
      <div className={Classes.User}>
        <p className={Classes.UserName}>{props.name}</p>
        <div className={Classes.Profle}>
          <img src={`data:image/jpeg;base64,${userProfile}`} alt="user" />
        </div>
        <img
          src={Logout}
          alt="logout"
          width={35}
          height={35}
          className={Classes.Logout}
          onClick={logoutHandler}
        />
      </div>
    </div>
  );
}
