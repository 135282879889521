import React,{useEffect} from "react";
import AuthLayout from "../components/Auth/AuthLayout/LoginAuthLay";
import Logins from "../components/Auth/Auth/Login";
import { Redirect } from "react-router-dom";

export default function Login() {
  
  useEffect(()=>{
    if(localStorage.getItem("accesToken") !== null){
     <Redirect to='/home'/>

    }

  },[])
  return (
    <div className="logBg">
      <div className="container">
        <div className="row d-flex justify-content-center align-items-center">
          <div className="col-md-6 box_parent">
            <AuthLayout>
              <h3 className="mainHead">Report Download portal</h3>
              <Logins />
            </AuthLayout>
          </div>
        </div>
      </div>
    </div>
  );
}
