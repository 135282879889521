export const server = 'https://faztest.in/'
// export const server = 'https://accesslabbeta.stagingserverhub.com/'
//login
export const login = server + 'api/b2b/json/login';
//reset
export const reset = server + 'api/b2b/send/otp'
//list order details
export const listOrder = server + 'api/b2b/order/details'
//logout
export const logout = server + 'api/session/logout'
//list doctor
export const listDoctor = server + 'api/b2b/doctors'
//search
export const filter = server + 'api/b2b/filter/orders?'
//change password
export const changePswd = server + 'api/b2b/reset/password'
//add msg
export const msg = server+ 'api/b2b/post/message'
//search suggetion
export const suggetion = server+ 'api/b2b/search/datas'
//seach by title
export const search = server + 'api/b2b/search/orders'
//verify
export const verify = server + 'api/b2b/send/otp'


